import {Component, forwardRef, inject, TemplateRef, ViewChild} from '@angular/core';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import * as Cesium from '@cesium/engine';
import {CesiumToolbarButton} from "../toolbar-extra-button-container/toolbar-extra-button-container.component";
import {toSignal} from "@angular/core/rxjs-interop";

@Component({
  standalone: true,
  selector: 'lib-north-button',
  templateUrl: './north-button.component.html',
  styleUrls: ['./north-button.component.css'],
  providers: [{provide: CesiumToolbarButton, useExisting: forwardRef(() => NorthButtonComponent)}]
})
export class NorthButtonComponent extends CesiumToolbarButton {

  @ViewChild('generalWidgetTemplate') generalWidgetTemplate: TemplateRef<any> | undefined;

  private cesiumService = inject(CesiumService);
  viewer$ = toSignal(this.cesiumService.watchViewerInit());

  get button(): TemplateRef<any> {
    const template = this.generalWidgetTemplate;
    if (!template) {
      throw new Error('Button template not found');
    }
    return template;
  }


  viewNorth() {
    const viewer = this.viewer$();
    if (!viewer) {
      return;
    }

    const camera = viewer.camera;

    // Convert the camera heading to north
    const carto = new Cesium.Cartographic(
      camera.positionCartographic.longitude,
      camera.positionCartographic.latitude,
      camera.positionCartographic.height);
    const dest = Cesium.Ellipsoid.WGS84.cartographicToCartesian(carto);

    const heading = Cesium.Math.toRadians(0.0);  // North
    const pitch = camera.pitch;
    const roll = camera.roll;

    camera.flyTo({
      destination: dest,
      orientation: {
        heading: heading,
        pitch: pitch,
        roll: roll
      }
    });

  }
}

