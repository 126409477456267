<ax-cesium-container [timeline]="false"
                     [toolbarZIndex]="'1000'"
                     [navigationInstructionsInitiallyVisible]="false"
                     [projectionPicker]="true"
                     [sceneModePicker]="false">
  <lib-infobox-container />
  <app-cesium-vol4d-query></app-cesium-vol4d-query>
  @if (sitRepEnabled) {
    <app-operation-sitrep-drawer [altRef]="altRef" [altUnits]="altUnits"></app-operation-sitrep-drawer>
    <app-constraint-sitrep-drawer [altRef]="altRef" [altUnits]="altUnits"></app-constraint-sitrep-drawer>
  }

  <app-laanc-drawer [type]="LaancCZMLEntityType.FACILITY_MAP" *ngIf="fasMapEnabled"/>
  <app-laanc-drawer [type]="LaancCZMLEntityType.CLASS_AIRSPACE" *ngIf="clsAirEnabled"/>
  <app-laanc-drawer [type]="LaancCZMLEntityType.SPECIAL_USE_AIRSPACE" *ngIf="suaEnabled"/>
  <app-laanc-drawer [type]="LaancCZMLEntityType.AIRPORTS" *ngIf="airportsEnabled"/>
  <app-laanc-drawer [type]="LaancCZMLEntityType.STADIUMS" *ngIf="stadiumsEnabled"/>
  <app-laanc-drawer [type]="LaancCZMLEntityType.NSUFRS" *ngIf="nsufrsEnabled"/>
  <app-laanc-drawer [type]="LaancCZMLEntityType.TFRS" *ngIf="tfrsEnabled"/>


  <lib-toolbar-extra-button-container>
    <lib-north-button/>
    <lib-cesium-toolbar-dropdown [title]="'Overlays'" [cdsIconName]="'map'">
      <ng-container clrForm clrLayout="vertical">
        <clr-toggle-container style="margin-top: 0px; min-width: 200px">
          <clr-toggle-wrapper>
            <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="sitRepEnabled"/>
            <label style="color: white!important">Situational Awareness</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
        @if (ussSettings$()?.laanc?.enabled) {
          <clr-toggle-container>
            <label style="color: white!important">LAANC Overlays</label>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="fasMapEnabled"/>
              <label style="color: white!important">UAS Facility Maps</label>
            </clr-toggle-wrapper>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="clsAirEnabled"/>
              <label style="color: white!important">Class Airspace</label>
            </clr-toggle-wrapper>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="suaEnabled"/>
              <label style="color: white!important">Special Use Airspace</label>
            </clr-toggle-wrapper>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}"
                     [(ngModel)]="airportsEnabled"/>
              <label style="color: white!important">Airports</label>
            </clr-toggle-wrapper>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}"
                     [(ngModel)]="stadiumsEnabled"/>
              <label style="color: white!important">Stadiums</label>
            </clr-toggle-wrapper>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="nsufrsEnabled"/>
              <label style="color: white!important">NSUFRs</label>
            </clr-toggle-wrapper>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="tfrsEnabled"/>
              <label style="color: white!important">TFRs</label>
            </clr-toggle-wrapper>
          </clr-toggle-container>
        }
      </ng-container>
    </lib-cesium-toolbar-dropdown>

  </lib-toolbar-extra-button-container>

</ax-cesium-container>
