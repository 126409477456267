import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {UserSettingsService} from '../../shared/services/user-settings.service';
import {Subscription} from 'rxjs';
import {units_of_measure, vertical_reference} from '../../shared/model/gen/utm';
import {MeasurementSystemType} from '../../shared/model/MeasurementSystem';
import {SceneMode} from '@cesium/engine';
import {toSignal} from "@angular/core/rxjs-interop";
import {LaancCZMLEntityType} from "../../shared/cesium/laanc-drawer/laanc-drawer.component";
import {SettingsService} from "../../shared/services/settings.service";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit, OnDestroy {

  ussSettings$ = toSignal(inject(SettingsService).getRawSettings());

  altUnits: units_of_measure;
  altRef: vertical_reference;
  sceneMode = SceneMode;

  private userSettingsSub: Subscription;

  LaancCZMLEntityType = LaancCZMLEntityType;
  fasMapEnabled = false;
  clsAirEnabled= false;
  sitRepEnabled= false;
  suaEnabled = false;
  stadiumsEnabled = false;
  airportsEnabled = false;
  nsufrsEnabled = false;
  tfrsEnabled = false;

  constructor(private userSettingsService: UserSettingsService) {
    // Constructor intentionally left empty

  }

  ngOnInit(): void {
    this.userSettingsSub = this.userSettingsService.getRawSettings().subscribe((rawSettings) => {
      switch (rawSettings.measurementSystemType) {
        case MeasurementSystemType.METRIC:
          this.altUnits = units_of_measure.M;
          break;
        case MeasurementSystemType.IMPERIAL:
          this.altUnits = units_of_measure.FT;
          break;
      }
      this.altRef = rawSettings.defaultVerticalReference;
    });
  }

  ngOnDestroy(): void {
    this.userSettingsSub?.unsubscribe();
  }
}
